import React, { useCallback, useContext, useEffect } from "react"
import { ACIContext } from "../../../contexts/ACIContext"

const HeroForm = ({
  isSubmitting,
  makeOptions,
  modelOptions,
  yearOptions,
  trimOptions,
  onEstimate,
}) => {
  const {
    isYear,
    year,
    make,
    model,
    trim,
    setIsYear,
    setYear,
    setMake,
    setMakeLabel,
    setModel,
    setTrim,
    resetForm,
  } = useContext(ACIContext)

  const handleYearSelector = useCallback(year => setIsYear(year), [setIsYear])

  const handleYear = useCallback(
    ({ target }) => setYear(target.value, true),
    [setYear]
  )

  const handleMake = useCallback(
    ({ target }) => {
      setMake(target.value, true)
      setMakeLabel(target.options[target.selectedIndex].text)
    },
    [setMake, setMakeLabel]
  )

  const handleModel = useCallback(
    ({ target }) => {
      const newModel = target.value;
  
      // Check if the model has changed, then reset sessionStorage
      const savedModel = sessionStorage.getItem("model");
      if (savedModel && savedModel !== newModel) {
        sessionStorage.clear(); // Clears all session data
      }
  
      // Store the new model in sessionStorage
      sessionStorage.setItem("model", newModel);
      
      setTrim(""); // Reset trim
      setModel(newModel);
    },
    [setModel, setTrim]
  );
  

  const handleTrim = useCallback(
    ({ target }) => setTrim(target.value),
    [setTrim]
  )

  // Use useEffect to extract the year, make, model and trim parameters from the URL to autopopulate form
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    
    // Extract parameters
    const urlYear = params.get('year');
    const urlMake = params.get('make');
    const urlModel = params.get('model');
    const urlTrim = params.get('trim');

    // Log extracted parameters


    // Update state with parameters and log updates
    if (urlYear) {
      setYear(urlYear, true);
   
    }
    if (urlMake) {
      setMake(urlMake, true);
   
  
      // Simulating what `handleMake` does
      const makeDropdown = document.getElementById("make"); // Ensure the dropdown exists
      if (makeDropdown) {
        const selectedOption = [...makeDropdown.options].find(option => option.value === urlMake);
        if (selectedOption) {
          setMakeLabel(selectedOption.text);
  
        } else {
          console.warn("No matching makeLabel found in dropdown for make:", urlMake);
        }
      } else {
        console.warn("Make dropdown not found in the DOM.");
      }
    }
    if (urlModel) {
      setModel(urlModel, true);
   
    }
    if (urlTrim) {
      setTrim(urlTrim, true);
  
    }
  }, [setYear, setMake, setModel, setTrim]);


  const Page = ({ location, data }) => {
    const canonicalUrl = data.site.siteMetadata.siteURL + location.pathname
  }

  return (
    <div className="col-12 col-md-6 form-wrapper">
      <div className="form-container">
        <h2 className="form-title">Calculate the value of your classic car</h2>
        <p className="form-subtitle">Enter the details of your vehicle</p>
        <div className="form">
          <div className="selector">
            <button
              className={isYear ? "is-year" : ""}
              onClick={() => handleYearSelector(true)}
            >
              Select By Year
            </button>
            <button
              className={!isYear ? "is-make" : ""}
              onClick={() => handleYearSelector(false)}
            >
              Select By Make
            </button>
          </div>

          {/* Year or Make Selection */}
          <div className="form-group">
            <label htmlFor={isYear ? "year" : "make"}>{`Select ${
              isYear ? "year" : "make"
            }`}</label>
            <select
              id={isYear ? "year" : "make"}
              className="form-select"
              aria-label={`Default select ${isYear ? "year" : "make"}`}
              value={isYear ? year : make}
              onChange={isYear ? handleYear : handleMake}
            >
              <option value="">{`Select ${isYear ? "year" : "make"}`}</option>
              {isYear ? yearOptions : makeOptions}
            </select>
          </div>

          {/* Make or Year Selection */}
          <div className="form-group">
            <label htmlFor={isYear ? "make" : "year"}>{`Select ${
              isYear ? "make" : "year"
            }`}</label>
            <select
              id={isYear ? "make" : "year"}
              className="form-select"
              aria-label={`Default select ${isYear ? "make" : "year"}`}
              disabled={
                isYear
                  ? !year || yearOptions.length <= 0
                  : !make || makeOptions.length <= 0
              }
              value={isYear ? make : year}
              onChange={isYear ? handleMake : handleYear}
            >
              <option value="">{`Select ${isYear ? "make" : "year"}`}</option>
              {isYear ? makeOptions : yearOptions}
            </select>
          </div>

          {/* Model Selection */}
          <div className="form-group">
            <label htmlFor="model">Select model</label>
            <select
              id="model"
              className="form-select"
              aria-label="Default select model"
              required
              disabled={!year || !make || modelOptions.length <= 0}
              value={model}
              onChange={handleModel}
            >
              <option value="">Select model</option>
              {year && make && modelOptions.length > 0 ? modelOptions : null}
            </select>
          </div>

          {/* Trim Selection */}
          <div className="form-group">
            <label htmlFor="trim">Select trim</label>
            <select
              id="trim"
              className="form-select"
              aria-label="Default select trim"
              required
              disabled={!model || trimOptions?.length <= 0}
              value={trim}
              onChange={handleTrim}
            >
              <option value="">Select trim</option>
              {model && trimOptions.length > 0 ? trimOptions : null}
            </select>
          </div>

          {/* Action Buttons */}
          <hr className="m-0" />
          <div className="action-buttons">
            <button
              disabled={isSubmitting}
              type="reset"
              className="btn-reset"
              onClick={resetForm}
            >
              Reset Selections
            </button>
            <button
              type="button"
              className="btn-estimate"
              disabled={
                isSubmitting ||
                !year ||
                !make ||
                !model ||
                (trimOptions?.length > 0 && !trim)
              }
              onClick={onEstimate}
            >
              Get Estimate
            </button>
          </div>
        </div>
      </div>

    </div>
  )
}

export default HeroForm